import React from "react"
import ContactProvider from "./src/internal/context/ContactContext"
import './global.css';


export const wrapRootElement = ({ element }) => {
  console.log('wrapper')
  return (
    <ContactProvider>
      {element}
    </ContactProvider>
  )
}