// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-404-js": () => import("./../../../src/pages/blog/404.js" /* webpackChunkName: "component---src-pages-blog-404-js" */),
  "component---src-pages-blog-common-mistakes-done-by-startup-founders-js": () => import("./../../../src/pages/blog/common-mistakes-done-by-startup-founders.js" /* webpackChunkName: "component---src-pages-blog-common-mistakes-done-by-startup-founders-js" */),
  "component---src-pages-blog-geo-location-based-data-operations-js": () => import("./../../../src/pages/blog/geo-location-based-data-operations.js" /* webpackChunkName: "component---src-pages-blog-geo-location-based-data-operations-js" */),
  "component---src-pages-blog-how-saas-solutions-can-transform-your-business-js": () => import("./../../../src/pages/blog/how-saas-solutions-can-transform-your-business.js" /* webpackChunkName: "component---src-pages-blog-how-saas-solutions-can-transform-your-business-js" */),
  "component---src-pages-blog-id-js": () => import("./../../../src/pages/blog/id.js" /* webpackChunkName: "component---src-pages-blog-id-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-low-code-no-code-prototyping-js": () => import("./../../../src/pages/blog/low-code-no-code-prototyping.js" /* webpackChunkName: "component---src-pages-blog-low-code-no-code-prototyping-js" */),
  "component---src-pages-blog-points-when-building-social-media-platforms-js": () => import("./../../../src/pages/blog/points-when-building-social-media-platforms.js" /* webpackChunkName: "component---src-pages-blog-points-when-building-social-media-platforms-js" */),
  "component---src-pages-blog-sailing-through-the-tide-covid-19-js": () => import("./../../../src/pages/blog/sailing-through-the-tide-covid-19.js" /* webpackChunkName: "component---src-pages-blog-sailing-through-the-tide-covid-19-js" */),
  "component---src-pages-blog-state-management-with-recoil-js-js": () => import("./../../../src/pages/blog/state-management-with-recoil-js.js" /* webpackChunkName: "component---src-pages-blog-state-management-with-recoil-js-js" */),
  "component---src-pages-consumer-markets-js": () => import("./../../../src/pages/consumer-markets.js" /* webpackChunkName: "component---src-pages-consumer-markets-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-404-js": () => import("./../../../src/pages/projects/404.js" /* webpackChunkName: "component---src-pages-projects-404-js" */),
  "component---src-pages-projects-[id]-js": () => import("./../../../src/pages/projects/[id].js" /* webpackChunkName: "component---src-pages-projects-[id]-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-netlise-js": () => import("./../../../src/pages/projects/netlise.js" /* webpackChunkName: "component---src-pages-projects-netlise-js" */),
  "component---src-pages-projects-wergo-js": () => import("./../../../src/pages/projects/wergo.js" /* webpackChunkName: "component---src-pages-projects-wergo-js" */),
  "component---src-pages-projects-wirl-js": () => import("./../../../src/pages/projects/wirl.js" /* webpackChunkName: "component---src-pages-projects-wirl-js" */),
  "component---src-pages-real-estate-js": () => import("./../../../src/pages/real-estate.js" /* webpackChunkName: "component---src-pages-real-estate-js" */),
  "component---src-pages-retail-crm-js": () => import("./../../../src/pages/retail-crm.js" /* webpackChunkName: "component---src-pages-retail-crm-js" */),
  "component---src-pages-services-artificial-intelligence-js": () => import("./../../../src/pages/services/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-js" */),
  "component---src-pages-services-data-analytics-js": () => import("./../../../src/pages/services/data-analytics.js" /* webpackChunkName: "component---src-pages-services-data-analytics-js" */),
  "component---src-pages-services-iot-js": () => import("./../../../src/pages/services/iot.js" /* webpackChunkName: "component---src-pages-services-iot-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-start-project-js": () => import("./../../../src/pages/start-project.js" /* webpackChunkName: "component---src-pages-start-project-js" */)
}

