import React, { createContext } from 'react';
import ContactRepository from '../ContactRepository';

export const ContactContext = createContext({});

const ContactProvider = ({ children }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const sendMessage = async (doc) => {
    setLoading(true);
    try {
      await ContactRepository.sendRequest(doc);
      setShowModal(false);
    } catch (e) {
      console.log(e)
    }
    setLoading(false);
  }

  return (
    <ContactContext.Provider value={{ setShowModal, showModal, sendMessage, loading }}>
      {children}
    </ContactContext.Provider>
  )

}

export default ContactProvider;